import React from 'react'
import classNames from 'classnames'

import Layout from '../components/layout'
import Meta from '../components/meta'
import utils from '../utils/utils'

class WaitingListPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isSubmitting: false,
      isEmailValid: true,
      animateError: false
    }
    this.emailInput = React.createRef()
  }

  onSubmit = (e) => {
    const isEmailValid = utils.isEmailValid(this.emailInput.current.value)
    if (!isEmailValid) {
      this.setState({
        isEmailValid,
        animateError: true
      })
      setTimeout(() => this.setState({animateError: false}), 1000)
      e.preventDefault()
    } else {
      this.setState({isSubmitting: true})
    }
  }

  onFocus = () => {
    this.setState({isEmailValid: true})
  }

  render () {
    return (
      <>
        <Meta
          title="Waiting List"
        />
        <Layout>
          <div className="typography-page limiter">
            <div className="waiting-list">
              <h2 className="ta-center">Join Waiting List</h2>
              <p>
                The Windows version is not ready yet, <br className="wide-screen-br"/>
                but we are working on it. Leave your email <br className="wide-screen-br"/>
                to be the first to know when we launch. <br className="wide-screen-br"/>
                No spam, <b>guaranteed</b>! Only <b>one</b> email.
              </p>
              <form
                onSubmit={this.onSubmit}
                action="/api/handle-windows-form-request"
                method="post"
                className="form"
              >
                <div className="form-control">
                  <label htmlFor="waiting-list-email" className="form-control__label">Email *</label>
                  <input
                    onFocus={this.onFocus}
                    ref={this.emailInput}
                    name="Email"
                    id="waiting-list-email"
                    type="text"
                    className={classNames('form-control__element', {
                      'form-control__element--error': !this.state.isEmailValid,
                      'error-shake': !this.state.isEmailValid && this.state.animateError
                    })}
                  />
                </div>
                <button
                  disabled={this.state.isSubmitting}
                  className="btn btn--primary form__submit"
                >
                  Notify Me
                </button>
              </form>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

export default WaitingListPage
